<template>
  <div class="wameed-dashboard-page-content">
    <page-header
        :title="$t('discounts.info')"
        :paths="[{title:$t('nav.discounts-vendors'),link:'discounts-vendors'}]"
    />
    <section class="wameed-dashboard-page-content_body coupons_create">
      <b-row class="px-2">
        <b-col lg="12" md="12" class="px-0">
          <b-card no-body class="wameed-card">


            <div class="package-details-table bg-white rounded-14">
              <h1 class="package-details-table__title text-font-main text-med-14">
                {{ $t('discounts.info') }}
              </h1>

              <detail-row
                  :title="$t('discounts.table.name')"
                  :value="discountInfo.name"
              />

              <detail-row
                  v-if="discountInfo.coupon"
                  :title="$t('discounts.form.code')"
              >
                <template v-slot:value>
                  <div class="d-flex align-items-center" style="gap: 16px">

                    <div class="d-inline-block " id="couponCode">
                      {{ discountInfo.coupon }}
                    </div>

                    <div slot="append-slot" class="coupon-code-copy"
                         style="padding: 4px 8px !important;border:1px solid gray" @click="copyCode">
                      <o-copy-icon/>
                      <span>{{ $t('discounts.copy') }}</span>
                    </div>
                  </div>
                </template>
              </detail-row>

              <detail-row
                  :title="$t('common.status')"
              >
                <template v-slot:value>

                  <div class="d-inline-block">
                    <discount-status-badge :status="discountInfo.status"/>

                  </div>
                </template>
              </detail-row>


              <b-row class="package-details-table__body">
                <b-col sm="4" md="4" lg="3" class="package-details-name">
                  <p class="mb-0">
                    {{ $t('discounts.table.type') }}
                  </p>
                </b-col>
                <b-col class="package-details-value">
                  <p class="mb-0">
                    <discount-category :category="discountInfo.category_id"/>
                  </p>
                </b-col>
              </b-row>

              <detail-row
                  :title="$t('discounts.form.on')"
                  :value="getDiscountOn"
              />


              <detail-row
                  v-if="discountInfo.product_discount"
                  :title="$t('discounts.section.product.value')"
                  :value="getDiscountType(discountInfo.product_discount)"
                  :detail="discountInfo.product_discount"
                  translate="provider_discount"
              />

              <detail-row
                  v-if="!isProductDiscount"
                  :title="$t('discounts.form.usage_limit')"
                  :value="discountInfo.usage_limit"
              />

              <detail-row
                  v-if="!isProductDiscount"
                  :title="$t('discounts.form.usage_per_user')"
                  :value="discountInfo.usage_per_user"
              />

              <detail-row
                  :title="$t('discounts.form.start_at')"
                  :value="discountInfo.start_at"
              />

              <detail-row
                  :title="$t('discounts.form.expire_at')"
                  :value="discountInfo.expire_at"
              />

              <detail-row
                  :title="$t('discounts.form.apply_for')"
                  :value="discount_apply_for"
              />

              <detail-row
                  v-if="discountInfo.order_discount"
                  :title="$t('discounts.section.order.value')"
                  :value="getDiscountType(discountInfo.order_discount)"
                  :detail="discountInfo.order_discount"
                  translate="provider_discount"
              />

              <detail-row
                  v-if="discountInfo.delivery_discount"
                  :title="$t('discounts.section.delivery.value')"
                  :value="getDiscountType(discountInfo.delivery_discount)"
                  :detail="discountInfo.delivery_discount"
                  translate="delivery_discount"
              />

            </div>
          </b-card>
        </b-col>
      </b-row>

      <usage :discount-id="discountInfo.id"/>
    </section>
  </div>
</template>

<script>

import {mapGetters} from 'vuex';

import PageHeader from '@/components/wameed/WameedPageHeader.vue';
import {wameedNotify,} from 'wameed-ui/dist/wameed-ui.esm'
import DetailRow from "@/views/pages/discounts/components/detailRow.vue";
import DiscountStatusBadge from "@/views/pages/discounts/components/discountStatusBadge.vue";
import Usage from "@/views/pages/discounts/detail/usage.vue";
import DiscountCategory from "@/views/pages/discounts/components/discountCategory.vue";
import {DiscountOn, DiscountOnTranslations} from "@/enums/discountOn.enum";


export default {
  components: {
    DiscountCategory,
    Usage,
    DiscountStatusBadge,
    DetailRow,

    PageHeader,
    wameedNotify,
  },
  data() {
    return {};
  },

  computed: {
    ...mapGetters({
      discountInfo: 'admin/discountsVendor/getDiscountInfo',
    }),

    discount_apply_for() {
      let data = '';
      for (let i = 0; i < this.discountInfo.apply_for.length; i++) {

        data += this.discountInfo.apply_for[i] + ' ,';

      }
      return data.slice(0, -1).replace('all', this.$i18n.t('common.all'));
    },

    discount_value() {
      if (this.discountInfo.discount_type == '%')
        return this.discountInfo.discount + '%';
      return this.discountInfo.discount
    },

    discount_type() {
      if (this.discountInfo.discount_type == '%')
        return this.$i18n.t('discounts.discounts.%')
      return this.$i18n.t('discounts.discounts.v')
    },
    getDiscountOn() {
      let item = DiscountOnTranslations.find((item) => item.id === this.discountInfo.discount_on_id)
      if (item)
        return item.name;
      return ''
    },
    isProductDiscount() {
      return this.discountInfo.discount_on_id === DiscountOn.products;
    },
  },

  methods: {


    copyCode() {

      navigator.clipboard.writeText(document.getElementById('couponCode').innerText).then(() => {
        wameedNotify({title: this.$t('common.copied'), type: 'success'})
      });

    },

    getDiscountType(discount) {
      if (discount) {
        if (discount.type === 'v')
          return discount.discount + " " + this.$i18n.t('discounts.discounts.v')
        return discount.discount + " %"
      }
    },


  },


  async created() {

    await this.$store.dispatch("admin/discountsVendor/discountInfo", this.$route.params.id)
    await this.$store.dispatch("admin/discountsVendor/discountUsage", {id: this.$route.params.id})

  },
};
</script>
